<template>
  <v-container style="width: 100%; overflow-y: scroll;">
    <!-- <product-fruits
        project-code="7LanuZNpsnyEaSZL"
        language="en"
        :username="user.email"
        :email="user.email"
        :firstname="user.first_name"
        :lastname="user.last_name"
        :sign-up-at="user.joined"
        :role="user.isOrderer ? 'orderer' : 'user'"
        :props="{ team_admin: user.isAdmin, sport: current_sport_id, team_orderer: user.isOrderer, plan_id: currentTeam.plan_id }"
    /> -->
    <v-card max-width="1200" class="mx-auto mt-3 pa-5">
      <h1>{{currentTeam.team_name}}</h1>
      <v-card-text class="mt-10 mb-9">
        <div>
          {{$t('admin.join_code_to')}} {{currentTeam.team_name}}
          <v-spacer></v-spacer>
          <v-btn @click="copy()" outlined x-large class="mt-2" :disabled="joinCodeState == 1">
            {{ currentTeam.join_code }}
            <v-icon class="ml-3">mdi-clipboard-outline</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <div style="display: flex; flex-direction: column; align-items: start;">
          <v-btn class="ml-0 mt-3" @click="showChangeDialog = true" x-small>
            <v-icon size="12" class="mr-3">
              mdi-autorenew
            </v-icon>
            {{$t('admin.change_code')}}
          </v-btn>
          <v-btn class="ml-0 mt-2" @click="showDisable" x-small>
            <v-icon size="12" class="mr-3">
              {{ joinCodeState == 1 ? 'mdi-check' : 'mdi-close' }}
            </v-icon>
            {{ joinCodeState == 1 ? $t('admin.activate_code') : $t('admin.disable_code')}}
          </v-btn>
        </div>
        </div>
        <div class="mt-8">
          <v-btn @click="invite_dialog = true" small>
            <v-icon class="mr-3">mdi-email-outline</v-icon>
            {{$t('admin.invite_via_email')}}
          </v-btn>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <router-view></router-view>
    </v-card>
    <confirmation-dialog
      :show="showChangeDialog"
      :text="$t('admin.change_code_question')"
      :btnText="$t('admin.change')"
      btnColor="primary"
      subText.sync=""
      @accept="changeCode()"
      @decline="showChangeDialog = false"
    ></confirmation-dialog>
    <confirmation-dialog
      :show="showDisableDialog"
      :text="$t('admin.disable_code')"
      :subText="$t('admin.disable_subtext')"
      :btnText="$t('admin.disable')"
      btnColor="primary"
      subText.sync=""
      @accept="toggleJoinCode()"
      @decline="showDisableDialog = false"
    ></confirmation-dialog>
    <v-dialog max-width="800" v-model="invite_dialog">
      <v-card>
        <v-card-title>
          {{$t('admin.invite_users_via_email')}}
        </v-card-title>
        <v-card-text>
          <div class="mb-1">
            {{$t('admin.split_by_comma')}}
          </div>
          <v-textarea solo v-model="emails_raw"/>
          <v-card-text :class="{'red--text': users_after_invite > currentTeam.users }">
            {{$t('team.users')}} {{ users_after_invite }} / {{ currentTeam ? currentTeam.users : 0 }}
          </v-card-text>
          <div v-if="emails_raw">
            <v-chip
              small
              class="mr-1"
              outlined
              v-for="email in emailsArray"
              :key="email"
            >
              {{ email }}
            </v-chip>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="emails_raw = ''; invite_dialog = false"> {{$t('cancel')}} </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="invite()" color="primary"> {{$t('invite')}} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ConfirmationDialog from '../components/ConfirmationDialog.vue';
export default {
  created() {
    if(this.$route.query.view == 'invite') {
      this.invite_dialog = true
    }
    this.getJoinCodeState(this.currentTeam.id)
  },
  components: {
    ConfirmationDialog
  },
  data: () => ({
    showChangeDialog: false,
    showDisableDialog: false,
    invite_dialog: false,
    emails_raw: ''
  }),
  methods: {
    ...mapActions('noti', [
      'showSnackbar',
      'success',
      'error'
    ]),
    ...mapActions('team', [
      'changeJoinCode',
      'inviteUsers',
      'getJoinCodeState',
      'setJoinCodeState'
    ]),
    copy() {
      navigator.clipboard.writeText(this.currentTeam.join_code);
      this.showSnackbar({
        text: this.$t('admin.join_code_copied'),
      })
    },
    invite() {
      this.inviteUsers(this.emailsArray)
        .then(() => {
          this.success('Users invited!')
          this.invite_dialog = false
          this.emails_raw = ''
          try {
            window.productFruits.api.checklists.markItemAsDone('add-member', true);
          } catch(e) {
            console.log(e)
          }
        })
        .catch(e => {
          this.error(e)
        })
    },
    changeCode() {
      this.changeJoinCode(this.currentTeam.id)
        .then(res => {
          this.showSnackbar({
            text: this.$t('admin.join_code_change_to', [res]),
          })
          this.showChangeDialog = false
        })
        .catch(e => alert(e))
    },
    showDisable() {
      if(this.joinCodeState == 0) this.showDisableDialog = true
      else this.toggleJoinCode()
    },
    async toggleJoinCode() {
      if(this.joinCodeState == 0) await this.setJoinCodeState({ team_id: this.currentTeam.id, disabledValue: 1})
      else await this.setJoinCodeState({ team_id: this.currentTeam.id, disabledValue: 0})

      this.getJoinCodeState(this.currentTeam.id)

      this.showDisableDialog = false
    }
  },
  computed: {
    ...mapGetters('user', [
      'currentTeam',
      'user',
      'current_sport_id'
    ]),
    ...mapGetters('team', [
      'users',
      'invites',
      'joinCodeState'
    ]),
    emailsArray() {
      if(this.emails_raw == '') return []
      return this.emails_raw.split(',').map(a => a.trim())
    },
    users_after_invite() {
      return this.users ? this.users.length + this.emailsArray.length : this.emailsArray.length
    }
  },
  watch: {
    '$route': {
      handler() {
        if(this.$route.query.view == 'invite') {
          this.invite_dialog = true
        }
      }
    }
  }
}
</script>